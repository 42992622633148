/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable max-len */
/* eslint-disable no-control-regex */
/* eslint-disable no-param-reassign */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable import/no-extraneous-dependencies */
import * as UI from '@contraktor-tech/ui';
import emailValidator from 'email-validator';
import { indexOf } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { IoMdPersonAdd, IoMdTrash } from 'react-icons/io';
import { Popup } from 'semantic-ui-react';
import { v4 as uuid } from 'uuid';

import { useAuth } from '../../../../../../containers/AuthContext/AuthContext';
import { useMixpanel } from '../../../../../../hooks/MixpanelProvider';
import Button from '../../../../../Button';
import ModalSearchPersons from '../../../../../Starter/ModalSearchPersons';
import { CustomTextField } from '../../../../Payment/shared/forms/CustomFieldStyles/CustomTextField';
import GridContainer from '../../../GridContainer/index';
import UpSellWhatsappField from './fields/UpSellWhatsappField';
import WhatsappField from './fields/WhatsappField';
import * as S from './styles';

const AddSignerForm = ({
  setHasInvalidPerson,
  open,
  addedSigners,
  setSigners,
  defaultSignatureType
}) => {
  const { Mixpanel } = useMixpanel();
  const initialPersons = [
    {
      id: uuid(),
      name: '',
      email: '',
      signatureType: defaultSignatureType || 'ASSINATURA_DIGITAL',
      focused: null,
      sendViaWhatsapp: false
    }
  ];
  const { features } = useAuth();
  const [persons, setPersons] = useState(
    addedSigners?.length > 0 ? addedSigners : initialPersons
  );
  const [popUp, setPopup] = useState(false);

  const verifyEmail = (value) => {
    const email = ((value === '' || !emailValidator.validate(value))
        && 'É necessário inserir um e-mail válido.')
      || (persons.find((p) => p.email === value)
        && 'E-mail inserido já existe nesta lista de assinantes.')
      || false;

    return email;
  };

  const verifyName = (value) => {
    const name = (value === '' && 'É necessário inserir o nome do assinante.') || false;

    return name;
  };

  const verifyWhatsapp = (validWhatsapp) => {
    const whatsapp = (!validWhatsapp && 'É necessário inserir um número de whatsapp válido.')
      || false;

    return whatsapp;
  };

  const handleAddFromModal = (name, email, whatsapp) => {
    const empty = persons.find((p) => p.name === '' || p.email === '');

    if (empty) {
      setPersons([
        ...persons.map((p) => {
          if (p.id === empty.id) {
            return {
              ...p,
              name,
              email,
              whatsapp,
              signatureType: defaultSignatureType || 'ASSINATURA_DIGITAL',
              focused: { ...p.focused, email: verifyEmail(email) }
            };
          }

          return p;
        })
      ]);
    } else {
      setPersons([
        ...persons,
        {
          id: uuid(),
          name,
          email,
          whatsapp,
          signatureType: defaultSignatureType || 'ASSINATURA_DIGITAL',
          focused: { email: verifyEmail(email) }
        }
      ]);
    }
  };

  const hasPersonNull = persons.find(
        (p) => (p && !p.name)
          || (p && !p.email)
          || (p.sendViaWhatsapp && !p.validWhatsapp)
          || (p.focused && p.focused.name)
          || (p.focused && p.focused.email)
          || (p.focused && p.focused.whatsapp)
      );

  useEffect(() => {
    if (open) {
      setHasInvalidPerson(hasPersonNull);
      setSigners(persons);
    }
  }, [open, persons]); // eslint-disable-line

  const handleNameChange = (person, value) => {
    const updatedPersons = persons.map((p) => (p.id === person.id
      ? {
        ...person,
        name: value,
        focused: { ...person.focused, name: verifyName(value) }
      }
      : p));

    setPersons(updatedPersons);
  };

  const handleEmailChange = (person, value) => {
    const updatedPersons = persons.map((p) => (p.id === person.id
      ? {
        ...person,
        email: value,
        focused: { ...person.focused, email: verifyEmail(value) }
      }
      : p));

    setPersons(updatedPersons);
  };

  const handleWhatsappChange = (person, { value, validWhatsapp }) => {
    const updatedPersons = persons.map((p) => (p.id === person.id
      ? {
        ...person,
        whatsapp: value,
        validWhatsapp: validWhatsapp,
        focused: { ...person.focused, whatsapp: verifyWhatsapp(validWhatsapp) }
      }
      : p));

    setPersons(updatedPersons);
  };

  const handleSendViaWhatsAppChange = (person) => {
    const updatedPersons = persons.map((p) => (p.id === person.id
      ? {
        ...person,
        sendViaWhatsapp: !p.sendViaWhatsapp
      }
      : p));
    setPersons(updatedPersons);
  };

  const handleAddPerson = (e) => {
    e.preventDefault();

    Mixpanel.track('document_signer_button_adicionar-participante');

    setPersons([
      ...persons.map((p) => ({
        ...p,
        focused: {
          ...p.focused,
          email: !emailValidator.validate(p.email) && 'E-mail inválido'
        }
      })),
      {
        id: uuid(),
        name: '',
        email: '',
        whatsapp: '',
        signatureType: defaultSignatureType || 'ASSINATURA_DIGITAL',
        focused: null,
        sendViaWhatsapp: false
      }
    ]);
  };

  const handleRemovePerson = (id) => {
    let updatedPersons = persons.filter((p) => p.id !== id);

    updatedPersons = updatedPersons.map((p) => {
      const email = ((p.email === '' || !emailValidator.validate(p.email))
          && 'É necessário inserir um e-mail válido.')
        || (updatedPersons.filter((u) => u.email === p.email).length > 1
          && 'E-mail inserido já existe nesta lista de assinantes.')
        || false;
      const name = (p.name === '' && 'É necessário inserir o nome do assinante.') || false;
      return {
        ...p,
        focused: {
          email,
          name
        }
      };
    });

    setPersons(updatedPersons);
  };

  const nameField = (person, focused) => (
    <CustomTextField
      name="name"
      variant="outlined"
      onChange={(event) => {
        handleNameChange(person, event.target.value);
      }}
      onBlur={() => Mixpanel.track('document_signer_input_nome')}
      value={person.name}
      focus={(focused && focused.name && true) || false}
      fullWidth
      label={(
        <S.InputLabel>
          <span>Nome e sobrenome</span>
        </S.InputLabel>
      )}
      id="name"
    />
  );

  const emailField = (person, focused) => (
    <CustomTextField
      name="email"
      variant="outlined"
      onChange={(event) => {
        handleEmailChange(person, event.target.value.toLowerCase().trim());
      }}
      onBlur={() => Mixpanel.track('document_signer_input_email')}
      value={person.email}
      focus={(focused && focused.email && true) || false}
      fullWidth
      label={(
        <S.InputLabel>
          <span>Email</span>
        </S.InputLabel>
      )}
      id="email"
      type="email"
    />
  );

  const hasWhatsappAccess = features
    && features.some((f) => f && f.name === 'whatsapp' && f.enabled === true);

  return (
    <>
      {persons?.map((p, index) => {
        const { id, focused } = p;

        return (
          <S.FormContainer
            style={{ paddingTop: indexOf(persons, p, 0) > 0 ? '26px' : '3px' }}
          >
            <GridContainer
              columns={persons.length > 1 ? '2fr 2fr 0.2fr' : '2fr 2fr'}
              width="auto"
              spacing="1.125"
              padding="0"
            >
              {nameField(p, focused)}
              {emailField(p, focused)}
              {persons.length !== 1 && (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <IoMdTrash
                    style={{
                      cursor: 'pointer',
                      width: '24px',
                      height: '24px'
                    }}
                    onClick={() => handleRemovePerson(id)}
                    name="trash alternate outline"
                  />
                </div>
              )}
              <small style={{ color: 'red' }}>
                {focused?.name || focused?.email}
              </small>
            </GridContainer>
            {hasWhatsappAccess ? (
              <WhatsappField
                person={p}
                index={index}
                focused={focused}
                onChange={handleWhatsappChange}
                onRadioChange={handleSendViaWhatsAppChange}
              />
            ) : (
              <UpSellWhatsappField />
            )}
          </S.FormContainer>
        );
      })}
      <Button link style={{ marginTop: '25px' }}>
        <ModalSearchPersons onAddPerson={handleAddFromModal} />
      </Button>
      <Popup
        content="Existem assinantes com o campo de nome/e-mail nulos ou repetidos, para adicionar mais assinantes é necessário preenchê-los."
        open={popUp}
        trigger={(
          <S.AddPersonContainer>
            <UI.Button
              style={{
                background: 'rgba(0, 0, 0, 0.08)',
                color: 'rgba(0, 0, 0, 0.6)',
                boxShadow: 'none',
                height: '42px',
                borderRadius: '8px',
                width: '100%',
                fontFamily: 'Poppins',
                fontWeight: '500',
                fonstSize: '0.875rem',
                letterSpacing: '1.25px'
              }}
              onClick={handleAddPerson}
              off={`${hasPersonNull}`}
              onMouseEnter={() => {
                if (hasPersonNull) setPopup(true);
              }}
              onMouseLeave={() => setPopup(false)}
              label={(
                <>
                  <IoMdPersonAdd
                    style={{ marginRight: 8, width: '19px', height: '18px' }}
                  />
                  Adicionar assinante
                </>
              )}
            />
          </S.AddPersonContainer>
        )}
      />
    </>
  );
};

AddSignerForm.defaultProps = {
  addedSigners: null,
  defaultSignatureType: null
};

AddSignerForm.propTypes = {
  setHasInvalidPerson: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  addedSigners: PropTypes.any,
  setSigners: PropTypes.func.isRequired,
  defaultSignatureType: PropTypes.string
};

export default AddSignerForm;
