/* eslint-disable react/forbid-prop-types */
/* eslint-disable max-len */
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Radio from '@mui/material/Radio';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import {
  AsYouType,
  isValidPhoneNumber,
  parsePhoneNumberFromString,
  parseIncompletePhoneNumber,
  formatIncompletePhoneNumber
} from 'libphonenumber-js';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FaWhatsapp } from 'react-icons/fa';
import { MdInfoOutline } from 'react-icons/md';

// import { CustomTextField } from '../../../../../Payment/shared/forms/CustomFieldStyles/CustomTextField';
import GridContainer from '../../../../GridContainer/index';
import CountryCodeSelector from '../CountryCodeSelector/CountryCodeSelector';
import * as S from '../styles';

const WhatsappField = ({
  person,
  focused,
  onRadioChange,
  onChange,
  index
}) => {
  const [dialCode, setDialCode] = useState('+55');
  const [countryCode, setCountryCode] = useState('BR');
  const [whatsapp, setWhatsapp] = useState('');
  const [formattedWhatsapp, setFormattedWhatsapp] = useState('');
  const [whatsappError, setWhatsappError] = useState(false);
  const [whatsappTouched, setWhatsappTouched] = useState(false);

  const separateDialCodeAndNumber = (phoneNumber) => {
    const phoneNum = parsePhoneNumberFromString(phoneNumber);

    if (!phoneNum) {
      return null; // Retorna nulo se o número de telefone não puder ser analisado
    }

    // Cria um objeto com o dial code e o número de telefone formatado nacionalmente
    return {
      dialCode: `+${phoneNum.countryCallingCode}`,
      number: phoneNum.formatNational()
    };
  };

  const formatPhoneNumber = (input, country) => {
    if (!isValidPhoneNumber(input, country))
      return input

    let formattedInput;
    
    // AsYouType does not format (XX) XXXX-XXXX properly, workaround
    const digits = input.replace(/\D/g, '');
    if (country === 'BR' && digits.length === 10) {
      const parsed = parseIncompletePhoneNumber(input, country);
      formattedInput = formatIncompletePhoneNumber(parsed, country);
    } else {
      const formatter = new AsYouType(country);
      formattedInput = formatter.input(input);
    }

    return formattedInput;
  };

  useEffect(() => {
    if (person.whatsapp) {
      const data = separateDialCodeAndNumber(person.whatsapp);

      setDialCode(data.dialCode || dialCode);
      setWhatsapp(data.number);
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    if (whatsapp && countryCode)
      setWhatsappError(
        whatsapp?.length < 14
          || !isValidPhoneNumber(whatsapp, countryCode)
      );
  }, [whatsapp, countryCode]);

  useEffect(() => {
    const formattedPhoneNumber = formatPhoneNumber(whatsapp, countryCode);
    setFormattedWhatsapp(formattedPhoneNumber);
  }, [whatsapp, countryCode, dialCode]); // eslint-disable-line

  useEffect(() => {
    if (formattedWhatsapp) {
      const normalizedNumber = parsePhoneNumberFromString(`${dialCode} ${formattedWhatsapp}`)?.number;

      onChange(person, { value: normalizedNumber, validWhatsapp: !whatsappError }, index);
    }
  }, [formattedWhatsapp]); // eslint-disable-line

  const sendViaWhatsappRadio = (p) => {
    const controlProps = {
      checked: p.sendViaWhatsapp,
      name: 'send_via_whatsapp',
      onClick: () => {
        onRadioChange(p);
      }
    };

    const radioButton = (
      <Radio {...controlProps} />
    );

    return (
      <>
        <FormControlLabel
          control={radioButton}
          label={(
            <S.InputLabel
              style={{
                fontWeight: 500
              }}
            >
              <span>Enviar documento via WhatsApp</span>
            </S.InputLabel>
          )}
          labelPlacement="end"
        />
        <span>{person.sendViaWhatsapp}</span>
      </>
    );
  };

  const tooltipContent = (
    <S.TooltipText>
      <span>
        Saiba mais sobre o envio por WhatsApp:
        <ul>
          <li>Cada assinante adicionado ao documento de assinatura consome um envio pelo WhatsApp.</li>
          <li>Cada reenvio do documento para assinatura também consome um novo envio.</li>
        </ul>
      </span>
    </S.TooltipText>
  );

  const whatsappInput = (
    <>
      <CountryCodeSelector
        onSelect={(value) => {
          setCountryCode(value.name);
          setDialCode(value.dialCode);
        }}
        defaultValue={dialCode}
      />
      <FormControl error={whatsappError || (whatsappTouched && whatsappError)}>
        <TextField
          id="whatsapp-text-field"
          name="whatsapp"
          variant="outlined"
          onChange={(event) => setWhatsapp(event.target.value)}
          onBlur={() => setWhatsappTouched(true)}
          value={formattedWhatsapp}
          focus={(focused && focused.whatsapp && true) || false}
          fullWidth
          label={(
            <S.InputLabel>
              <span>Número Whatsapp</span>
              <FaWhatsapp style={{
                color: '#25D366',
                height: '20px',
                width: '20px',
                marginLeft: '0.5rem'
              }}
              />
            </S.InputLabel>
          )}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end" style={{ height: 'fit-content' }}>
                <Tooltip title={tooltipContent} placement="right">
                  <IconButton style={{ padding: 0 }}>
                    <MdInfoOutline style={{ color: '#000000A6', height: '20px', width: '20px' }} />
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            )
          }}
        />
      </FormControl>
    </>
  );

  return (
    <>
      <S.SendToWhatsappContainer
        columns="1fr"
        width="auto"
      >
        {sendViaWhatsappRadio(person)}
      </S.SendToWhatsappContainer>
      {
        person.sendViaWhatsapp && (
        <>
          <GridContainer
            columns="0.2fr 0.8fr"
            width="auto"
            spacing="1.125"
            padding="1.125rem 0 0 0"
          >
            { whatsappInput }
          </GridContainer>
          <small style={{ color: 'red' }}>{(focused && focused.whatsapp && true)}</small>
          {whatsappError && <FormHelperText style={{ color: 'red' }}>O número informado é inválido</FormHelperText>}
        </>
        )
      }
    </>
  );
};

WhatsappField.propTypes = {
  person: PropTypes.any.isRequired,
  focused: PropTypes.bool.isRequired,
  onRadioChange: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired
};

export default WhatsappField;
