/* eslint-disable react/no-array-index-key */
/* eslint-disable consistent-return */
import {
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@mui/material';
import { debounce } from 'lodash';
import React, {
  forwardRef, useCallback, useEffect, useState
} from 'react';
import { useForm, Controller } from 'react-hook-form';

import CardDateField from '../../../../../_components/CardDateField';
import CCVField from '../../../../../_components/CCVField';
import CreditCardField from '../../../../../_components/CreditCardField';
import { VOUCHERS } from '../../../../../_utils/vouchers';
import { useChoosePlan } from '../../../../../../_legacy/containers/NewPaymentPage/contexts/ChoosePlanContext';
import { useMixpanel } from '../../../../../../_legacy/hooks/MixpanelProvider';
import { validateVoucher } from '../../../../../../_legacy/services/api/adapters/gateway';
import { CustomMenuItem, FlexField, Form } from './styles';

const VOCEFOIESCOLHIO_VOUCHER_DISCOUNT = 66.89;

const PaymentForm = forwardRef(
  ({
    onSubmit, defaultValues, setIsValid, setVoucher
  }, ref) => {
    const {
      cardNumber, holderName, expirationDate, ccv, voucher
    } = defaultValues;

    const { Mixpanel } = useMixpanel();
    const selectedPlan = new URLSearchParams(
      window.location.search.split('?')[1]
    ).get('selectedPlan');
    const selectedVoucher = new URLSearchParams(
      window.location.search.split('?')[2]
    ).get('voucher');

    const {
      plansList,
      setPlanSelected,
      planSelected,
      currentPlanInfo,
      getPriceDescription
    } = useChoosePlan();
    const [filteredPlans, setFilteredPlans] = useState(plansList);
    const [plansOptions, setPlansOptions] = useState([]);
    const [voucherMessage, setVoucherMessage] = useState('');
    const [voucherDiscount, setVoucherDiscount] = useState(0);
    const [validatedVoucher, setValidatedVoucher] = useState({});

    const {
      control,
      handleSubmit,
      formState: { errors, isValid },
      setValue,
      setError,
      clearErrors
    } = useForm({
      defaultValues: {
        cardNumber,
        holderName,
        expirationDate,
        ccv,
        voucher
      },
      mode: 'onChange',
      reValidateMode: 'onBlur'
    });

    console.log(plansList, 'plansList');

    useEffect(() => {
      const foundedVoucher = VOUCHERS.find((v) => v.code === validatedVoucher.code);
      if (foundedVoucher) {
        setPlansOptions(
          plansList.filter(
            (p) => foundedVoucher.plans.includes(p.param)
          )
        );
        setVoucherDiscount(foundedVoucher.discount);
        return;
      }

      setPlansOptions(
        plansList.filter((p) => {
          if (currentPlanInfo.planName === 'Unlimited') {
            return p.planName === 'Unlimited';
          }
          return true;
        })
      );

      console.log(plansOptions, 'plansOptions');
      console.log(validatedVoucher, 'validatedVoucher');
    }, [validatedVoucher, voucher]); //eslint-disable-line

    const handleVoucherError = (error) => {
      setVoucherMessage(error.response.data.message);
      setVoucherDiscount(0);
      setError('voucher');
      setVoucher(null);
    };

    const handleVoucherSuccess = (v) => {
      const { code, discountPercent } = v;

      const message = code && code.toUpperCase() === '5REAIS'
        ? 'Desconto aplicado com sucesso! A primeira mensalidade será no valor de R$5,00'
        : `Desconto de ${discountPercent}% aplicado com sucesso!`;

      setVoucherMessage(message);
      setVoucherDiscount(discountPercent);
      setVoucher(
        code === 'VCFOIESCOLHIDO'
          ? { ...v, discountPercent: VOCEFOIESCOLHIO_VOUCHER_DISCOUNT }
          : v
      );
      setValidatedVoucher(v);
    };

    const checkVoucherValidity = useCallback(
      async (voucherCode) => {
        if (!!voucherCode && voucherCode !== '') {
          try {
            const v = await validateVoucher({
              voucher: voucherCode,
              planName: planSelected.planName,
              cycle:
                planSelected.frequency === 'ANNUAL'
                  ? 'YEARLY'
                  : planSelected.frequency
            });

            handleVoucherSuccess(v.data);
            clearErrors('voucher');

            return true;
          } catch (error) {
            handleVoucherError(error, voucherCode);
            setValidatedVoucher({});
            return false;
          }
        }
        setValidatedVoucher({});
        setVoucherDiscount(0);
        setVoucherMessage('');
        clearErrors('voucher');
      },
      [planSelected.frequency, planSelected.planName] //eslint-disable-line
    );

    const validateCardDate = useCallback(
      (value) => {
        const inputValue = value;

        const numericValue = inputValue.replace(/\D/g, '');
        const month = parseInt(numericValue.substring(0, 2), 10);
        const year = parseInt(numericValue.substring(2, 6), 10);

        const currentYear = new Date().getFullYear();

        if (month > 12) {
          setError('expirationDate', {
            message: 'Mês inválido'
          });
          return false;
        }
        if (year < currentYear) {
          setError('expirationDate', {
            message: 'Ano inválido'
          });
          return false;
        }

        clearErrors('expirationDate');
        return true;
      },
      [setError, clearErrors]
    );

    const handlePlanSelect = useCallback(
      (event) => {
        const plan = JSON.parse(event.target.value);

        setPlanSelected(plan);
      },
      [setPlanSelected]
    );

    useEffect(() => {
      Mixpanel.track('checkout_input_plano', { plan_type: planSelected });
    }, [planSelected, Mixpanel]);

    useEffect(() => {
      setPlansOptions(
        plansList.filter((p) => {
          if (currentPlanInfo.planName === 'Unlimited') {
            return p.planName === 'Unlimited';
          }
          return true;
        })
      );

      if (selectedPlan && !planSelected) {
        setPlanSelected(filteredPlans.find((p) => p.param === selectedPlan));
      } else if (
        currentPlanInfo.planName === 'Unlimited'
        || currentPlanInfo.planName === 'Light'
      ) {
        setFilteredPlans(
          plansList.filter((p) => p.planName === currentPlanInfo.planName)
        );
        setPlanSelected(
          filteredPlans.find((p) => p.name === currentPlanInfo.name)
        );
      }
    }, []); // eslint-disable-line

    useEffect(() => {
      if (selectedVoucher) {
        setValue('voucher', selectedVoucher);
        checkVoucherValidity(selectedVoucher);
      }
    }, [selectedVoucher, setValue, checkVoucherValidity, planSelected.param]); // eslint-disable-line

    useEffect(() => {
      setIsValid(isValid);
    }, [isValid, setIsValid]);

    return (
      <Form ref={ref} onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="plan"
          control={control}
          defaultValue={JSON.stringify(planSelected)}
          rules={{
            required: 'Plano é obrigatório',
            onChange: handlePlanSelect
          }}
          render={({ field }) => (
            <FormControl error={!!errors.state}>
              <InputLabel>Plano</InputLabel>
              <Select {...field} label="Plano" name="plan">
                {plansOptions.reduce((acc, plan, index) => {
                  if (index > 0 && index % 3 === 0) {
                    acc.push(<Divider key={`divider-${index}`} />);
                  }
                  acc.push(
                    <MenuItem key={plan.name} value={JSON.stringify(plan)}>
                      <CustomMenuItem>
                        <b>{plan.planName}</b>
                        &nbsp;{plan.paymentDescription}
                        &nbsp;-{' '}
                        {getPriceDescription(
                          plan.priceFloat,
                          plan.planName,
                          plan.frequency,
                          true,
                          voucherDiscount
                        )}
                      </CustomMenuItem>
                    </MenuItem>
                  );
                  return acc;
                }, [])}
              </Select>
              {errors.plan && (
                <p style={{ color: 'red' }}>{errors.plan.message}</p>
              )}
            </FormControl>
          )}
        />
        <Controller
          name="cardNumber"
          control={control}
          defaultValue={cardNumber}
          rules={{
            required: 'Número do cartão é obrigatório',
            pattern: {
              value: /^\d{4}\s\d{4}\s\d{4}\s\d{4}$/,
              message: 'Número do cartão inválido'
            },
            onChange: () => Mixpanel.track('checkout_input_numero-cartao')
          }}
          render={({ field }) => (
            <CreditCardField
              props={field}
              error={!!errors.cardNumber}
              helperText={errors.cardNumber ? errors.cardNumber.message : ''}
            />
          )}
        />
        <Controller
          name="holderName"
          control={control}
          defaultValue={holderName}
          rules={{
            required: 'Nome do cartão é obrigatório',
            onChange: () => Mixpanel.track('checkout_input_nome-cartao')
          }}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              name="holderName"
              label="Nome do cartão"
              variant="outlined"
              error={!!errors.holderName}
              helperText={errors.holderName ? errors.holderName.message : ''}
            />
          )}
        />
        <FlexField>
          <Controller
            name="expirationDate"
            control={control}
            defaultValue={expirationDate}
            rules={{
              required: 'Data de expiração é obrigatória',
              validate: validateCardDate,
              onChange: () => Mixpanel.track('checkout_input_data-validade')
            }}
            render={({ field }) => (
              <CardDateField
                props={field}
                error={!!errors.expirationDate}
                helperText={
                  errors.expirationDate ? errors.expirationDate.message : ''
                }
                style={{
                  width: '56.25%'
                }}
              />
            )}
          />
          <Controller
            name="ccv"
            control={control}
            defaultValue={ccv}
            rules={{
              required: 'Código de segurança é obrigatório',
              onChange: () => Mixpanel.track('checkout_input_cvc')
            }}
            render={({ field }) => (
              <CCVField
                props={field}
                style={{
                  width: '43.75%'
                }}
                error={!!errors.ccv}
                helperText={errors.ccv ? errors.ccv.message : ''}
              />
            )}
          />
        </FlexField>
        <Controller
          name="voucher"
          control={control}
          defaultValue={voucher}
          rules={{
            validate: debounce(async (value) => {
              const valid = await checkVoucherValidity(value);

              if (!valid) {
                Mixpanel.track('checkout_input_cupom-desconto-response', {
                  sucess: false
                });
              }
              Mixpanel.track('checkout_input_cupom-desconto-response', {
                sucess: true
              });

              return valid;
            }, 500),
            onChange: () => Mixpanel.track('checkout_input_cupom-desconto')
          }}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              name="voucher"
              label="Cupom de desconto"
              variant="outlined"
              error={!!errors.voucher}
              helperText={voucherMessage}
            />
          )}
        />
      </Form>
    );
  }
);

export default PaymentForm;
